* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-bar {
  font-size: 15px;
  background-color: rgb(30, 30, 30);
  height: 100px;
  display: flexbox;
}
.tool-bar {
  justify-content: start;
  margin-bottom: auto;
  margin-top: auto;
}
.header-text {
  margin-top: 35px;
  margin-bottom: 5px;
  font-size: 55px;

  display: block;
}
.header-p {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 40px;
  display: block;
}
.logo {
  margin-right: 30px;
  margin-left: 10px;
  height: 60px;
}

.mainostoimisto {
  margin-right: 15px;
  font-weight: 700;
}
.digisovellukset {
  padding: 10px 10px;
  margin-top: 15px;
}

.divider {
  margin-bottom: 10px;
}
