*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.select-campaign-button{
    color: rgb(50, 50, 50);
    background-color: orange;
    width: 33%;
    display:flex;
    align-self: left;
    padding: 2% 5%;
    
}

.select-campaign-button:hover{
    color: orange;
    background-color: rgb(50, 50, 50);
}

.add-campaign-button{
    color: rgb(50, 50, 50);
    background-color: white;
    width: 33%;
    display:flex;
    align-self: left;
    padding: 2% 5%;
    
}

.add-campaign-button:hover{
    color: orange;
    background-color: rgb(50, 50, 50);
}

h5.old-campaigns{
    width: auto;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.campaign-select-container{
    padding: 20px 30px;
}

.campaign-select-input{
    width:75%;
    margin-bottom: 15px;
    
}

h5.add-campaign {
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.add-campaign-container{
    padding: 20px 30px;
}

.campaign-name-input{
    margin-bottom: 15px;
    width: 75%;
}
.MuiTextField-root{
    border: none;
}
.MuiOutlinedInput-root
 {border: '1px solid orange'; }