.box-texts {
  display: inline-flex;
  justify-content: baseline;
  margin-bottom: 20px;
}
.campaign-name {
  display: flex;
  margin: 0;
}
.edit-campaign-name {
  margin: 5px 25px;
  display: flex;
}
.edit-campaign-time {
  margin-top: 5px;
  margin-right: 25px;

  display: flex;
}
.campaign-desc {
  margin-left: 32px;

  display: inline-block;
}
.update-button {
  display: flex;
}
.update-button:hover {
  color: orange;
  background-color: black;
}
.upload-button:hover {
  color: orange;
  background-color: black;
}
.delete-campaign-button {
  background-color: black;
  color: orange;
}
.delete-campaign-button:hover {
  background-color: red;
  color: black;
}

.banner-box-wrap:hover {
  transform: scale(1.01);

  background: linear-gradient(to right, #ffffff, #f2f2f2, #efefef);
  background-size: 400% 400%;
  animation: animate-background 5s infinite ease-in-out;
}

@keyframes animate-background {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/*Kampanjakortti

"& .MuiInputLabel-root": { color: "black" },
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: "orange" },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "orange",
                      },
                      "& .css-1sumxir-MuiOutlinedInput-root.MuiFocused": {
                        color: "black",
                      },
                    },

*/
.banner-box {
  margin: 0px 0px;
  display: flex;
  justify-content: space-between;
  display: flex;
}
.banner-box-wrap {
  margin-bottom: 20px;
}
.banner-texts {
  display: block;
  box-sizing: border-box;
}
.banner-size-text {
  font-size: 15px;
  margin-right: -15px;
  font-weight: bold;
  padding-top: 5px;
  display: block;
}
.banner-description-text {
  font-size: 12px;
  font-weight: bold;
  color: rgb(100, 100, 100);
}
.thumbnail-image {
  display: flex;

  padding: 1px 0px;
  margin-bottom: 2px;
  box-sizing: border-box;
}
.banner-card-buttons {
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  border-top: 1px orange solid;
}
.divider {
  border-right: 1px orange solid;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.delete-icon {
  padding: 5px 5px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  border-radius: 0;
}
.preview-button {
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  border-radius: 0;
}
.get-file-button {
  border-radius: 5px;
  border: none;
  background-color: orange;
}

.upload-button {
  width: 100%;
  display: flex;
  justify-content: left;
}
.upload-button2 {
  display: flex;
  justify-content: left;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.upload-button2:hover {
  color: orange;
  background-color: black;
}

input[type="file" i] {
  background-color: aliceblue;
}
