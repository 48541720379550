.header {
    box-sizing: border-box;
    background-color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 0px;
    margin-bottom: -3em;
    

}
body {
    background: linear-gradient(
    to right, #ffffff,
    #f5f5f5, #ffffff
    );
    background-size: 400% 400%;
    animation: animate-background 25s infinite ease-in-out;
    
}

@keyframes animate-background {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}


